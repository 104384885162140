var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("PrintingPositionManagementTemplate", {
        attrs: {
          title: _vm.$t("title.agency.editPrintPosition"),
          images: _vm.images,
          temporaryId: _vm.temporaryId,
          onComplete: _vm.onComplete,
          onCancel: _vm.onCancel,
          cancelButtonText: _vm.$t("button.cancel"),
          completeButtonText: _vm.$t("button.update"),
          applicationFormDetails: _vm.applicationFormData,
          type: "edit"
        }
      }),
      _c("CompletedDialog", { ref: "completedDialog" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
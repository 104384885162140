<template>
  <v-container>
    <PrintingPositionManagementTemplate
      :title="$t('title.agency.editPrintPosition')"
      :images="images"
      :temporaryId="temporaryId"
      :onComplete="onComplete"
      :onCancel="onCancel"
      :cancelButtonText="$t('button.cancel')"
      :completeButtonText="$t('button.update')"
      :applicationFormDetails="applicationFormData"
      type="edit"
    />
    <!-- 印字位置更新完了通知 -->
    <CompletedDialog ref="completedDialog" />
  </v-container>
</template>

<script>
import {
  getApplicationFormMaster,
  postApplicationFormPrintPosition,
  getApplicationFormData,
} from '@/apis/agency/applicationForms';
import PrintingPositionManagementTemplate from '@/pages/agency/templates/PrintingPositionManagementTemplate';
import { getPdfImage, getPdfBlobToB64 } from '@/lib/commonUtil';
import CompletedDialog from '@/components/organisms/agency/CompletedDialog';
import { mapActions } from 'vuex';

export default {
  name: 'EditPrintingPosition',
  components: {
    PrintingPositionManagementTemplate,
    CompletedDialog,
  },
  data() {
    return {
      images: [],
      temporaryId: 1,
      applicationFormData: null,
    };
  },
  created() {
    this.setLoading(true);
  },
  beforeDestroy() {
    this.setLoading(false);
  },
  async mounted() {
    const applicationFormId = this.$route.params['applicationFormId'];
    if (!applicationFormId) {
      this.onCancel();
      return;
    }
    // 申込書を初期化する
    const applicationFormBlob = await getApplicationFormMaster(
      applicationFormId
    );
    const b64 = await getPdfBlobToB64(applicationFormBlob);
    this.$set(this, 'images', await getPdfImage(b64));
    this.applicationFormData = await getApplicationFormData(applicationFormId);

    this.setLoading(false);
  },
  methods: {
    ...mapActions('ui', ['setLoading']),

    async onComplete(printX, printY, printPage) {
      await postApplicationFormPrintPosition(
        this.$route.params['applicationFormId'],
        { printX: printX, printY: printY, printPage: printPage }
      );
      this.$refs.completedDialog.open(
        this.$t('title.agency.editPrintPosition'),
        this.$t('description.completeUpdatePrintingPosition')
      );
    },
    onCancel() {
      this.$router.go(-1);
    },
  },
};
</script>

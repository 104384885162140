import { render, staticRenderFns } from "./EditPrintingPosition.vue?vue&type=template&id=5218aec4&"
import script from "./EditPrintingPosition.vue?vue&type=script&lang=js&"
export * from "./EditPrintingPosition.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VContainer})


/* hot reload */
if (module.hot) {
  var api = require("/Users/imaiyuurika/sj_contract_procedure_contractor_front/admin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5218aec4')) {
      api.createRecord('5218aec4', component.options)
    } else {
      api.reload('5218aec4', component.options)
    }
    module.hot.accept("./EditPrintingPosition.vue?vue&type=template&id=5218aec4&", function () {
      api.rerender('5218aec4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/agency/EditPrintingPosition.vue"
export default component.exports